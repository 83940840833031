import React from 'react';
import Helmet from 'react-helmet';
import get from 'lodash/get';
import { graphql } from 'gatsby';

import Layout from '../components/layout';
import Image from '../components/image';
import ContentTitle from '../components/ContentTitle';
import AboutPage from '../components/AboutPage';
import card from '../images/logo_card.svg';
import logo from '../images/limlogo.svg';

class Evolved extends React.PureComponent {
  render() {
    const page = get(this, 'props.data.contentfulPage');
    return (
      <Layout>
        <Helmet>
          <title>We Have Evolved</title>
        </Helmet>
        <ContentTitle></ContentTitle>
        <AboutPage>
        <AboutPage.Image>
        <img src={logo} height={400} alt="Goodbye!" />
        </AboutPage.Image>
        <AboutPage.Content
            dangerouslySetInnerHTML={{
              __html: page.content.childMarkdownRemark.html,
            }}
        />
        </AboutPage>
      </Layout>
    );
  }
}
export const pageQuery = graphql`
  query PageBySlug {
    contentfulPage(slug: { eq: "we-have-evolved" }) {
      content {
        childMarkdownRemark {
          html
        }
      }
      image {
        gatsbyImageData(layout: CONSTRAINED, width: 800, placeholder: BLURRED)
      }
    }
  }
`;

export default Evolved;
