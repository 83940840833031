import styled from 'styled-components';

import { devices } from '../../styles';

const Image = styled.div`
  min-width: 444px;
  max-width: 832px;
  flex: 1;

  

  @media ${devices.mobile} {
    min-width: 100%;
  }
`;

const Content = styled.div`
  width: 475px;
  text-align: center;

  @media ${devices.mobile} {
    width: 100%;
  }
`;

const AboutPage = styled.div`
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  flex-direction: row;
`;

AboutPage.Image = Image;
AboutPage.Content = Content;

export default AboutPage;
